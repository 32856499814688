<template>
	<div class="contact-person">
		<div class="contact-person__title">{{ $t('titles.contact_person') }}</div>

		<form
			@submit.prevent="submit"
			class="contact-person__form contact-person-form"
			id="contact-person-form"
		>
			<tk-input
				v-model="name"
				type="text"
				@input="clearError('name')"
				:error="errors.name"
				:isClearButton="false"
				class="contact-person-form__item"
				placeholder="Имя (по документам)"
				autocomplete="off"
			/>

			<tk-input
				v-model="relation"
				type="text"
				@input="clearError('relation')"
				:error="errors.relation"
				:isClearButton="false"
				class="contact-person-form__item"
				placeholder="Кем приходится (мама, папа, друг и т.д.)"
				autocomplete="off"
			/>

			<tk-input
				v-model="phone"
				type="tel"
				@input="clearError('phone')"
				:error="errors.phone"
				:isClearButton="false"
				class="contact-person-form__item"
				placeholder="Номер телефона"
				autocomplete="off"
			/>

			<button class="btn contact-person-form__submit" :disabled="isDisabled">
				{{ $t('form.buttons.save_title') }}
			</button>
		</form>
	</div>
</template>

<script>
import errorHandler from '@/utils/errorHandler'

import { SET_FIELD } from '@/constants/store/user/mutations'

export default {
	mixins: [errorHandler],
	data() {
		return {
			name: '',
			relation: '',
			phone: '',

      inProcess: false,
		}
	},
	created() {
		this._createFieldsError(['phone', 'name', 'relation'])
	},
	mounted() {
		if (this.contactPerson) {
			this.name = this.contactPerson.name
			this.relation = this.contactPerson.relation
			this.phone = this.contactPerson.phone
		}
	},
	computed: {
		user() {
			return this.$store.state.user
		},
		isDisabled() {
			return !this.name || !this.phone || this.inProcess
		},
		contactPerson() {
			return this.user.contact_person
		}
	},
	methods: {
		async submit() {
      this.inProcess = true
			const payload = {
				name: this.name,
				phone: this.phone.replace(/[^+\d]/g, '')
			}

			if (this.relation) {
				payload.relation = this.relation
			}

			const response = await this.$api.user.setContactPerson({ ...payload })

			if (response && response.error) {
        this.inProcess = false
				return this.setError(response)
			}

			this.$store.commit(SET_FIELD, {
				field: 'contact_person',
				value: { ...payload }
			})

			this.$store.commit('showNotification', {
				type: 'success',
				text: 'Контактное лицо успешно добавлено/изменено'
			})

			setTimeout(() => {
				this.$router.push('/profile/anketa')
			})
      this.inProcess = false
		}
	}
}
</script>

<style lang="sass">
.contact-person
	position: relative
	&__title
		font-weight: bold
		font-size: 24px
		line-height: 24px
		color: #3B3F47
		margin-bottom: 30px
	&__form
		display: flex
		flex-direction: column
		width: 420px

	.contact-person-form
		&__item
			margin-bottom: 20px

		&__submit
			height: 55px

			color: $green
			border: 1px solid $green

			background: #ffffff
			border-radius: 10px

// 540
@media screen and (max-width: 800px)
	.contact-person
		width: 100%
		&__form
			width: 100%
</style>
